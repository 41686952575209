<template>
	<div class="mt-lg-4">
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<button v-if="(!isLg && !isXl) && mares_selected_local.length > 0" class="btn btn-primary btn-next" @click="sendMares()">{{ $t('pax.choisir_etalons') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button>
			
			<div class="row align-items-center">
				<div class="col">
					<h3>1. {{ $t('pax.mes_juments') }}</h3>
				</div>
				<div class="col-auto">
					<div class="input-group stallion_search">
					    <button class="input-group-text px-3" @click="openIntroduction"><font-awesome-icon :icon="['fas', 'info']" /></button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="paragraphes">{{ $t('pax.text_juments_infos') }}</div>
				</div>
			</div>
			<div class="row stallions-list">
				<div class="col-6" v-for="mare in mares" :key="mare.horse_id" @click="selectMare(mare)" :class="{active: mares_selected_local.find((elem) => { return elem.horse_id == mare.horse_id }), 'col-lg-4' : step_label == 'mares' || step_label == ''}">
					<div class="stallion">{{ mare.horse_nom }}</div>
				</div>
			</div>
		</div>

		<b-modal dialog-class="modal-pax" ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t('pax.pax_inverse') }}
			</template>

			<div v-html="text_intro"></div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import _cloneDeep from 'lodash/cloneDeep';
	import _remove from 'lodash/remove';
	import Window from '@/mixins/Window.js'

	export default {
		name: "Mares",
		props: {
			mares_selected: {
				type: Array,
				default: () => ( [] )
			},
			step_label: {
				type: String,
				default: () => null
			},
		},
		mixins: [Navigation, PaxMixin, Window],
		data () {
			return {
				addOrEditReady: false,
				search: '',
				mares: [],
				text_intro: '',
				mares_selected_local: []
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.text_intro = this.getConfig('translations')['pax_reverse'][Vue.i18n.locale()]
				this.addOrEditReady = false

				const country = this.getConfig('country')
				if(!country) {
					this.$emit("update:step_label", "country")
					return false
				}

				this.mares = await this.getMares()
				

				this.addOrEditReady = true
			},
			
			selectMare(horse) {
				if(this.mares_selected_local.find((elem) => { return elem.horse_id == horse.horse_id }) == undefined) {
					if((this.pax_express && this.mares_selected_local.length < 2) || (!this.pax_express && this.mares_selected.length < 10)) {
						this.mares_selected_local.push(horse)
					}
				}
				else {
					const idx = this.mares_selected_local.findIndex(mare => mare.horse_id == horse.horse_id)
					this.mares_selected_local.splice(idx, 1)
				}

				if(this.mares_selected_local.length == 0) {
					this.$emit("update:mares_selected", [])
				}
			},

			sendMares() {
				this.$emit("update:step_label", "mares_selected")
				this.$emit("update:mares_selected", _cloneDeep(this.mares_selected_local))
			},

			openIntroduction() {
				this.$refs["modal"].show()
			},

		},
		watch: {
			mares_selected_local(val) {
				if(val.length > 0 && val !== this.mares_selected && (this.isLg || this.isXl)) {
					this.sendMares()
				}
			},
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
